import React, { useState, useEffect, useCallback, useRef } from 'react';
import questions from '../questions';

const QUESTION_WIDTH = 900; // Increased width for larger text area
const QUESTION_HEIGHT = 20; // Increased height for larger text area
const ROW_HEIGHT = QUESTION_HEIGHT + 2; // Adjusted row height for spacing
const NUM_QUESTIONS = 40; // Number of questions per row to fit the screen better
const SCROLL_DURATION = 60000; // Scroll duration
const MODAL_PADDING = 10; // Padding around the modal to avoid overlap

const QuestionBackground = () => {
  const [questionQueue, setQuestionQueue] = useState([]);
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [modalRect, setModalRect] = useState({ top: 0, left: 0, width: 0, height: 0 });

  const getRandomQuestion = useCallback(() => {
    const allQuestions = Object.values(questions).flat();
    return allQuestions[Math.floor(Math.random() * allQuestions.length)];
  }, []);

  const createRowsOfQuestions = useCallback(() => {
    const numRows = Math.ceil(containerSize.height / ROW_HEIGHT);
    const newQuestionQueue = [];

    for (let row = 0; row < numRows; row++) {
      const rowQuestions = [];
      for (let col = 0; col < NUM_QUESTIONS; col++) {
        rowQuestions.push({
          id: `${row}-${col}`,
          text: getRandomQuestion(),
          row,
          col,
          direction: row % 2 === 0 ? 'left' : 'right'
        });
      }
      newQuestionQueue.push(rowQuestions);
    }

    setQuestionQueue(newQuestionQueue);
  }, [containerSize, getRandomQuestion]);

  useEffect(() => {
    const updateSizes = () => {
      if (containerRef.current) {
        setContainerSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight
        });
      }
      const modalElement = document.querySelector('.modal');
      if (modalElement) {
        const rect = modalElement.getBoundingClientRect();
        setModalRect({
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height
        });
      }
    };

    updateSizes();
    window.addEventListener('resize', updateSizes);
    return () => window.removeEventListener('resize', updateSizes);
  }, []);

  useEffect(() => {
    createRowsOfQuestions();
  }, [createRowsOfQuestions]);

  const isInModalArea = (x, y) => {
    return (
      x < modalRect.left + modalRect.width + MODAL_PADDING &&
      x + QUESTION_WIDTH > modalRect.left - MODAL_PADDING &&
      y < modalRect.top + modalRect.height + MODAL_PADDING &&
      y + QUESTION_HEIGHT > modalRect.top - MODAL_PADDING
    );
  };

  return (
    <div ref={containerRef} className="fixed inset-0 overflow-hidden pointer-events-none" style={{ zIndex: 1 }}>
      {questionQueue.map((rowQuestions, rowIndex) => (
        <div
          key={rowIndex}
          className="absolute whitespace-nowrap"
          style={{
            top: `${rowIndex * ROW_HEIGHT}px`,
            width: '100%',
            display: 'flex',
            justifyContent: rowIndex % 2 === 0 ? 'flex-start' : 'flex-end',
            animation: `scroll-${rowIndex % 2 === 0 ? 'left' : 'right'} ${SCROLL_DURATION}ms linear infinite`,
            paddingLeft: rowIndex % 2 === 0 ? `${containerSize.width}px` : '0',
            paddingRight: rowIndex % 2 !== 0 ? `${containerSize.width}px` : '0',
          }}
        >
          {[...rowQuestions, ...rowQuestions].map((question, index) => {
            const x = index * (QUESTION_WIDTH + 40); // Added spacing between questions
            const y = rowIndex * ROW_HEIGHT;
            if (isInModalArea(x, y)) {
              return null;
            }
            return (
              <div
                key={question.id + '-' + index}
                className="inline-block text-white text-xl font-semibold text-center"
                style={{
                  width: QUESTION_WIDTH,
                  height: QUESTION_HEIGHT,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0.1, // Faded opacity
                  padding: '10px'
                }}
              >
                {question.text}
              </div>
            );
          })}
        </div>
      ))}
      <style jsx>{`
        @keyframes scroll-left {
          from {
            transform: translateX(-200%);
          }
          to {
            transform: translateX(-150%);
          }
        }
        @keyframes scroll-right {
          from {
            transform: translateX(-100%);
          }
          to {
            transform: translateX(0%);
          }
        }
      `}</style>
    </div>
  );
};

export default QuestionBackground;
