const questions = {
    Office: [
        "If you could merge two animals to create the ultimate pet, what would they be and why?",
        "If you could have any fictional character as your office assistant, who would it be and what would they do?",
        "What’s your secret superhero identity and what are your powers?",
        "If you could only eat one food for the rest of your life, but it had to be an unusual color, what would it be and why?",
        "If you had to be a fruit, which one would you be and why?",
        "If you could teleport anywhere in the world for lunch, where would you go and what would you eat?",
        "If you were a flavor of ice cream, which one would you be and why?",
        "What would be the title of your autobiography if it was written by your pet?",
        "If you could switch lives with any cartoon character, who would it be and why?",
        "If your life had a theme song, what would it be and when would it play?",
        "If you could have a mythical creature as a pet, which one would it be and why?",
        "What’s the weirdest thing you’ve ever eaten and did you like it?",
        "If you could create a new holiday, what would it be called and how would it be celebrated?",
        "If you could have any animal's tail, which one would you choose and why?",
        "What would you do if you woke up one day with an extra set of arms?",
        "If you could have dinner with any three fictional characters, who would they be and why?",
        "If you could turn any activity into an Olympic sport, what would you have a good chance of winning a gold medal for?",
        "What’s your zombie apocalypse survival plan?",
        "If you were a mad scientist, what experiment would you run if ethics and money weren’t an issue?",
        "If you could instantly become an expert in any obscure skill, what would it be and why?",
        "If you could live in any TV show universe, which one would you choose?",
        "If you could invent a new office tradition, what would it be?",
        "If you were a ghost, how would you haunt your workplace?",
        "What’s your go-to karaoke song and why?",
        "If you had to live in a video game world, which one would you pick and why?",
        "What’s the most ridiculous fact you know?",
        "If you could swap places with any historical figure for a day, who would it be and why?",
        "If you were a kitchen utensil, which one would you be and why?",
        "If you could bring back any fashion trend, what would it be and why?",
        "If you could have any animal speak for one minute, what would you ask it?",
        "What’s the weirdest dream you’ve ever had?",
        "If you had to create a new sport, what would it involve?",
        "If you could design a new office mascot, what would it be and what would its name be?",
        "What would be your weapon of choice in a food fight?",
        "If you could be an extra in any movie, which one would you choose?",
        "If you could live in any time period, which one would you pick and why?",
        "What’s the funniest thing that has happened to you recently?",
        "If you could make one of your hobbies into a profession, what would it be?",
        "What’s the most unusual gift you’ve ever received?",
        "If you could instantly learn any language, which one would it be and why?",
        "What’s the craziest thing you would do if you knew there were no consequences?",
        "If you could turn any fictional place into a real one, which one would you choose?",
        "What would be your strategy to win at hide-and-seek in the office?",
        "If you could have any celebrity as a coworker, who would it be and why?",
        "What’s your guilty pleasure song that you secretly love?",
        "If you were a character in a video game, what would your special ability be?",
        "What’s the most bizarre thing you’ve ever witnessed?",
        "If you could have a dinner party with any three historical figures, who would you invite and why?",
        "What’s the weirdest talent you have?",
        "If you had to perform in a talent show right now, what would your act be?"
    ],

    "This or That": [

        "Would you rather always have a perfect hair day or never have to shave again?",
        "Would you rather be stuck in an elevator with someone who talks non-stop or with someone who doesn't talk at all?",
        "Would you rather have a job you love in a location you hate or a job you hate in a location you love?",
        "Would you rather be the star player on a losing team or sit on the bench on a winning team?",
        "Would you rather live without air conditioning or without heating?",
        "Would you rather eat only sweet foods or only savory foods for the rest of your life?",
        "Would you rather have an endless summer or an endless winter?",
        "Would you rather live in a treehouse or a houseboat?",
        "Would you rather have the ability to always find what you're looking for or always know when someone is lying?",
        "Would you rather have to listen to only one song for the rest of your life or never be able to listen to the same song twice?",
        "Would you rather travel everywhere by bicycle or be able to fly but only as fast as you can walk?",
        "Would you rather be the funniest person in the room or the most attractive?",
        "Would you rather have every meal you eat be too spicy or too bland?",
        "Would you rather have a rewind button or a fast forward button on your life?",
        "Would you rather be able to talk to animals or control the weather?",
        "Would you rather live on a deserted island with your favorite book or with your favorite movie?",
        "Would you rather always be able to make people laugh or always be able to make people cry?",
        "Would you rather have an extra hour in the day or need one less hour of sleep?",
        "Would you rather be able to change one decision in your past or have the ability to see one decision in your future?",
        "Would you rather always have to wear formal clothes or always have to wear pajamas?",
        "Would you rather be able to read minds or see into the future?",
        "Would you rather have a third eye or a third arm?",
        "Would you rather always be able to remember names or always be able to remember faces?",
        "Would you rather have unlimited access to books or unlimited access to movies?",
        "Would you rather live in a house with a view of the mountains or a view of the ocean?",
        "Would you rather always have to eat out or always have to cook at home?",
        "Would you rather be able to play every musical instrument or speak every language fluently?",
        "Would you rather have the power of teleportation or telepathy?",
        "Would you rather live without music or without the internet?",
        "Would you rather have the ability to erase memories or create new ones?",
        "Would you rather always have to whisper or always have to shout?",
        "Would you rather be able to remember every dream you've ever had or never dream again?",
        "Would you rather live in a world without cheese or a world without chocolate?",
        "Would you rather be able to breathe underwater or fly through the air?",
        "Would you rather be constantly cold or constantly hot?",
        "Would you rather have the ability to instantly learn any skill or the ability to instantly forget any embarrassing moment?",
        "Would you rather have a personal stylist or a personal trainer?",
        "Would you rather always have to eat your least favorite food or never be able to eat your favorite food again?",
        "Would you rather be able to talk to plants or be able to talk to animals?",
        "Would you rather always know what time it is or always know what the weather will be?",
        "Would you rather be a master at every board game or a master at every video game?",
        "Would you rather be able to taste colors or see sounds?",
        "Would you rather live in a world where everyone sings or a world where everyone dances?",
        "Would you rather always have to walk backwards or always have to walk on your hands?",
        "Would you rather have a photographic memory or be able to forget anything you want?",
        "Would you rather have a magical wardrobe that can take you anywhere or a magical refrigerator that always has your favorite food?",
        "Would you rather be able to shrink to the size of a mouse or grow to the size of a giant?",
        "Would you rather be able to control fire or control water?",
        "Would you rather live in a world where you can only whisper or only shout?"
    ],

    Memories: [
        "What's the most unforgettable trip we've taken together?",
        "What's your favorite photo of us and why?",
        "What's the first thing you noticed about me?",
        "What's the funniest memory we share?",
        "What's the most surprising thing I've ever done for you?",
        "What's the first meal we cooked together?",
        "What's your favorite inside joke that we have?",
        "What's the best gift I've ever given you?",
        "What's your favorite song that reminds you of us?",
        "What's the most meaningful conversation we've ever had?",
        "What's the first movie we watched together?",
        "What's the most adventurous thing we've done together?",
        "What's the most thoughtful thing I've ever done for you?",
        "What's the most beautiful place we've visited together?",
        "What's the first restaurant we went to on a date?",
        "What's the most spontaneous thing we've done together?",
        "What's the first thing you said to me?",
        "What's the most memorable celebration we've shared?",
        "What's the best compliment I've ever given you?",
        "What's the most romantic gesture I've made?",
        "What's your favorite tradition we've started together?",
        "What's the most challenging situation we've faced together?",
        "What's the best surprise I've ever planned for you?",
        "What's the most fun we've had on a holiday?",
        "What's the most touching thing I've written to you?",
        "What's the first gift you gave me?",
        "What's the most embarrassing moment we've had together?",
        "What's the first song we danced to?",
        "What's the best meal we've shared?",
        "What's the most memorable party we've attended together?",
        "What's the first trip we took together?",
        "What's the funniest thing that's ever happened to us?",
        "What's the most comforting thing I've ever done for you?",
        "What's the most unique date we've been on?",
        "What's the most unexpected place we've discovered together?",
        "What's the best advice I've given you?",
        "What's the most memorable thing about our first kiss?",
        "What's the first place we said 'I love you'?",
        "What's the most interesting thing we've learned about each other?",
        "What's the most relaxing day we've spent together?",
        "What's the first hobby we tried together?",
        "What's the most memorable festival or event we've attended?",
        "What's the first time we cooked together like?",
        "What's the best decision we've made together?",
        "What's the most heartfelt gift I've ever given you?",
        "What's the most inspirational thing you've ever said to me?",
        "What's the most emotional moment we've shared?",
        "What's the most unique gift I've given you?",
        "What's the most fun we've had doing nothing together?"
    ],
    Future: [
        "Where do you see us living in the next 10 years?",
        "What's one place you'd like us to travel to in the future?",
        "What kind of house do you envision us living in?",
        "What's a new hobby you'd like us to try together?",
        "What's one thing you hope to accomplish with me by your side?",
        "What's a tradition you'd like us to start?",
        "What's your dream vacation for us?",
        "What's one skill you'd like us to learn together?",
        "What's your vision for our future family?",
        "How do you see us spending our retirement years?",
        "What are your career goals for the next five years?",
        "What’s one thing you want to improve about our relationship in the future?",
        "What’s a future project you'd like us to work on together?",
        "What's one personal goal you have that you'd like my support with?",
        "How do you see our financial future?",
        "What’s one adventure you’d like us to go on?",
        "How do you see us celebrating our anniversaries in the future?",
        "What’s one future milestone you're excited about?",
        "What kind of pets do you see us having?",
        "What’s one way you want to grow as a couple?",
        "What’s a new tradition you want to create with our future children?",
        "What’s a future challenge you think we’ll face together?",
        "What’s one thing you want us to always remember to do for each other?",
        "How do you see our relationship evolving over the next decade?",
        "What’s your dream for our family holidays?",
        "What’s one thing you want to make sure we do more of in the future?",
        "What’s one dream you have for us that we haven’t talked about?",
        "How do you see us balancing work and family life?",
        "What’s one future experience you want to share with me?",
        "What kind of impact do you hope we’ll have on each other’s lives?",
        "What’s one thing you’re looking forward to in our future together?",
        "How do you see us handling future conflicts?",
        "What’s one thing you hope we never stop doing?",
        "What’s one big risk you’d like us to take together?",
        "What kind of legacy do you want us to build?",
        "What’s one thing you hope we learn from each other?",
        "What’s a financial goal you have for us?",
        "What’s one future event you’re excited about?",
        "How do you see us celebrating our 50th wedding anniversary?",
        "What’s a future tradition you want to start with me?",
        "What’s one thing you hope we always support each other in?",
        "What’s a new habit you’d like us to develop together?",
        "What kind of community do you see us being a part of?",
        "What’s one future memory you’re excited to create?",
        "How do you see us handling future health challenges?",
        "What’s one dream you want to achieve together?",
        "What’s a future goal you have for our home?",
        "What’s one thing you want to make sure we always prioritize?",
        "What’s your vision for our next big adventure?"
    ],
    "Diving Deep": [
        "What's one fear you have about our relationship?",
        "How do you think our past experiences have shaped our relationship?",
        "What's one thing you wish you could change about our relationship?",
        "How do you handle it when you feel hurt by me?",
        "What's one thing you think we need to work on together?",
        "How do you define true love?",
        "What's one aspect of our relationship that makes you feel most secure?",
        "How do you see us handling future crises?",
        "What's one thing about your past you wish I understood better?",
        "How do you feel about the way we handle disagreements?",
        "What's one thing you'd like me to understand about your feelings?",
        "How do you cope with stress in our relationship?",
        "What's one way you think we can deepen our connection?",
        "How do you feel about our level of emotional intimacy?",
        "What's one thing you're afraid to tell me?",
        "How do you feel about our communication?",
        "What's one thing that you think could improve our trust?",
        "How do you handle feelings of jealousy?",
        "What's one thing that makes you feel vulnerable with me?",
        "How do you see our relationship evolving emotionally?",
        "What's one thing you admire about the way we solve problems together?",
        "How do you feel about the balance of power in our relationship?",
        "What's one thing that makes you feel most appreciated?",
        "How do you think our relationship impacts your mental health?",
        "What's one thing you think we need to do to grow as a couple?",
        "How do you feel about the way we share responsibilities?",
        "What's one thing you think we can do to better support each other's dreams?",
        "How do you feel when we spend time apart?",
        "What's one thing you think we need to be more honest about?",
        "How do you feel about the way we express love to each other?",
        "What's one thing you think we need to talk about more?",
        "How do you handle feelings of disappointment in our relationship?",
        "What's one thing that makes you feel most connected to me?",
        "How do you feel about our physical intimacy?",
        "What's one thing you think we need to change to be happier together?",
        "How do you feel about our shared goals?",
        "What's one thing you think we need to work on individually for the sake of our relationship?",
        "How do you handle it when you feel misunderstood by me?",
        "What's one thing you think we need to let go of?",
        "How do you feel about our future together?",
        "What's one thing you think we can do to make our relationship last?",
        "How do you handle feelings of insecurity in our relationship?",
        "What's one thing that makes you feel most loved?",
        "How do you feel about the way we handle finances together?",
        "What's one thing you think we need to do to improve our relationship?",
        "How do you feel about our ability to handle life's challenges together?",
        "What's one thing you think we need to be more open about?",
        "How do you feel about the way we make decisions together?",
        "What's one thing you think we need to prioritize more?"
    ],
    Funny: [
        "What's the funniest thing I've ever done without realizing it?",
        "If you could prank me without any consequences, what would you do?",
        "What's the weirdest nickname you've ever given me?",
        "What's the most ridiculous argument we've ever had?",
        "If you could make up a new holiday for us, what would it be?",
        "What's the funniest misunderstanding we've ever had?",
        "If we had our own sitcom, what would it be called?",
        "What's the silliest thing we've done together?",
        "If you could switch places with me for a day, what's the first thing you'd do?",
        "What's the funniest thing you've seen me do while I was asleep?",
        "If we could be any fictional couple, who would we be?",
        "What's the weirdest food combination you've ever seen me eat?",
        "What's the most embarrassing thing I've done in public?",
        "If you could create a funny invention for me, what would it be?",
        "What's the funniest face I've ever made?",
        "What's the most hilarious photo we have together?",
        "If you could give me a funny superhero name, what would it be?",
        "What's the craziest adventure we've had?",
        "What's the silliest way we've ever solved a problem?",
        "If we had a reality show, what would be our catchphrase?",
        "What's the funniest way we've ever spent a holiday?",
        "What's the most amusing thing we've done on a lazy day?",
        "If you could make a meme about me, what would it say?",
        "What's the goofiest song we've danced to together?",
        "What's the funniest outfit I've ever worn?",
        "What's the most ridiculous thing we've bought together?",
        "If we could star in a comedy movie, what would it be about?",
        "What's the funniest dream you've had about us?",
        "What's the silliest thing we've done on a date?",
        "If we had a pet with a funny name, what would it be?",
        "What's the most unexpected funny moment we've shared?",
        "What's the goofiest voice I've ever used?",
        "If you could write a funny book about our life, what would it be titled?",
        "What's the most hilarious thing we've cooked together?",
        "What's the funniest reaction you've seen from me?",
        "If we could create a funny dance, what would it be called?",
        "What's the most amusing thing we've done in a serious situation?",
        "What's the silliest game we've played together?",
        "If you could give me a funny award, what would it be?",
        "What's the funniest thing I've said in my sleep?",
        "What's the most ridiculous thing we've tried and failed at?",
        "If we had a funny secret handshake, what would it look like?",
        "What's the most comical place we've visited?",
        "What's the weirdest habit I have that makes you laugh?",
        "If we could create a funny challenge for each other, what would it be?",
        "What's the most amusing way we've ever communicated?",
        "What's the funniest surprise I've ever given you?",
        "If we could make a funny parody of a movie, what would it be?",
        "What's the most laugh-out-loud moment we've had?",
        "What's the funniest excuse we've ever used to get out of something?"
    ],
    Dare: [
        "Do an impression of me for one minute.",
        "Send a funny selfie to your best friend.",
        "Sing the chorus of your favorite song in a funny voice.",
        "Do a silly dance for 30 seconds without music.",
        "Try to lick your elbow.",
        "Talk in an accent for the next three turns.",
        "Make up a story about a random object in the room.",
        "Let me draw a mustache on your face with a washable marker.",
        "Speak only in questions until your next turn.",
        "Do 10 push-ups and shout 'I love you' after each one.",
        "Let me post a silly status on your social media.",
        "Try to touch your nose with your tongue.",
        "Give a compliment to the next person you see.",
        "Do your best animal impression for 30 seconds.",
        "Eat a spoonful of a condiment of my choice.",
        "Speak in a whisper for the next three turns.",
        "Let me tickle you for 30 seconds.",
        "Do an interpretive dance to a song I choose.",
        "Try to juggle three random objects.",
        "Draw a self-portrait using your non-dominant hand.",
        "Let me style your hair in a funny way.",
        "Do your best impression of a celebrity.",
        "Let me blindfold you and feed you a mystery food.",
        "Act like a chicken until your next turn.",
        "Try to stand on one leg for one minute.",
        "Make up a poem about a random object in the room.",
        "Let me choose an outfit for you to wear for the next hour.",
        "Imitate a famous movie scene dramatically.",
        "Balance a book on your head and walk across the room.",
        "Speak in rhymes for the next three turns.",
        "Do a cartwheel or your best attempt at one.",
        "Eat a slice of lemon without making a face.",
        "Impersonate your favorite cartoon character.",
        "Do 20 jumping jacks and shout 'I'm awesome' after each one.",
        "Try to do a magic trick for me.",
        "Let me give you a temporary tattoo with a marker.",
        "Do your best impression of a robot.",
        "Make a funny face and hold it for one minute.",
        "Try to say the alphabet backward.",
        "Let me paint your nails with a funny color.",
        "Do your best impression of an opera singer.",
        "Speak like a pirate until your next turn.",
        "Try to do the worm dance move.",
        "Hold an ice cube in your hand until it melts.",
        "Do your best impression of a baby crying.",
        "Let me give you a silly nickname and use it until your next turn.",
        "Try to hula hoop for one minute.",
        "Let me wrap you up like a mummy using toilet paper.",
        "Do your best impression of a sports announcer."

    ],
    Truth: [
        "What is one thing you’ve never told me?",
        "What’s the most embarrassing thing that has ever happened to you?",
        "Have you ever had a crush on someone you shouldn’t have?",
        "What is your biggest fear in life?",
        "What’s one secret you’ve kept from your parents?",
        "What’s the most awkward date you’ve ever been on?",
        "Have you ever lied to get out of trouble?",
        "What’s the worst gift you’ve ever received?",
        "Have you ever stolen something?",
        "What’s your biggest regret?",
        "What’s the most childish thing you still do?",
        "What’s the worst thing you’ve ever done to someone?",
        "Have you ever been in love with more than one person at the same time?",
        "What’s the most trouble you’ve ever gotten into?",
        "Have you ever cheated on a test?",
        "What’s the most disgusting thing you’ve ever done?",
        "Have you ever told a lie about someone to make yourself look better?",
        "What’s the most embarrassing thing in your room?",
        "Have you ever broken the law?",
        "Who is your secret crush?",
        "What’s the worst thing you’ve ever said to someone?",
        "Have you ever faked being sick to get out of something?",
        "What’s the most embarrassing text message you’ve ever sent?",
        "What’s your biggest insecurity?",
        "Have you ever ghosted someone?",
        "What’s the most jealous you’ve ever been?",
        "Have you ever spread a rumor?",
        "What’s the longest you’ve gone without showering?",
        "Have you ever snooped through someone’s phone?",
        "What’s the worst lie you’ve ever told a significant other?",
        "Have you ever had a crush on a friend’s partner?",
        "What’s the most inappropriate thing you’ve ever done in public?",
        "Have you ever peed in a pool?",
        "What’s the weirdest dream you’ve ever had?",
        "Have you ever been caught doing something you shouldn’t have?",
        "What’s the biggest lie you’ve ever told your best friend?",
        "Have you ever been in a physical fight?",
        "What’s the most embarrassing thing you’ve ever posted on social media?",
        "Have you ever kissed someone of the same sex?",
        "What’s the most inappropriate joke you’ve ever told?",
        "Have you ever lied about your age?",
        "What’s the biggest secret you’ve ever kept from a partner?",
        "Have you ever cheated in a relationship?",
        "What’s the most awkward thing you’ve done on a date?",
        "Have you ever been rejected by someone you liked?",
        "What’s the worst date you’ve ever been on?",
        "Have you ever lied about where you were going?",
        "What’s the most embarrassing thing you’ve done in front of a crush?",
        "Have you ever had a one-night stand?",
        "What’s the most awkward conversation you’ve ever had?"
    ],
    Cute: [
        "What is your favorite thing about my smile?",
        "What's the most adorable thing you've ever seen me do?",
        "If we could cuddle anywhere in the world, where would it be?",
        "What's your favorite nickname for me?",
        "What's the sweetest thing I've ever done for you?",
        "What's the cutest outfit you think I look best in?",
        "What's your favorite memory of us being silly together?",
        "If we had a pet together, what would you name it?",
        "What's the most heartwarming compliment you've ever received from me?",
        "What's your favorite way to spend a cozy day with me?",
        "What's the cutest text message I've ever sent you?",
        "What's your favorite cute photo of us?",
        "What's the sweetest thing I've ever said to you?",
        "What's the most romantic thing we've done together?",
        "What's your favorite cute thing about our relationship?",
        "What's the most endearing habit I have?",
        "What's the cutest gift I've ever given you?",
        "What's the most adorable thing you can imagine us doing together?",
        "What's the sweetest way I've ever surprised you?",
        "What's your favorite cute nickname I've given you?",
        "What's the most heartwarming thing we've done for each other?",
        "What's the most romantic place we've been together?",
        "What's your favorite way we show affection?",
        "What's the cutest thing you've ever seen me wear?",
        "What's your favorite sweet gesture I've made?",
        "What's the most adorable story you have about us?",
        "What's your favorite cute memory from our early days together?",
        "What's the sweetest thing we've said to each other?",
        "What's the most heartwarming thing we've shared?",
        "What's the cutest way we've resolved an argument?",
        "What's your favorite place to snuggle with me?",
        "What's the most romantic movie we've watched together?",
        "What's the sweetest song that reminds you of us?",
        "What's your favorite way to show me love?",
        "What's the cutest way we've celebrated a special occasion?",
        "What's your favorite thing about our hugs?",
        "What's the most adorable thing I've done to make you laugh?",
        "What's your favorite cute tradition we've started?",
        "What's the sweetest thing we've done on a date?",
        "What's the most endearing thing about our conversations?",
        "What's your favorite cute memory from a trip we've taken?",
        "What's the sweetest thing you've ever written to me?",
        "What's your favorite way to say 'I love you'?",
        "What's the cutest pet name we have for each other?",
        "What's the most heartwarming compliment I've given you?",
        "What's your favorite way to wake up next to me?",
        "What's the most adorable dream you've had about us?",
        "What's the sweetest way we hold hands?",
        "What's your favorite way we make each other smile?"
    ],
    "Weekly": [
        "What was the highlight of your week?",
        "What's one thing that stressed you out this week?",
        "What’s something you accomplished this week that you’re proud of?",
        "How did we support each other this week?",
        "Is there anything you wish had gone differently this week?",
        "What's one thing you’re grateful for this week?",
        "What’s one way we can improve our communication for next week?",
        "How did you feel about our time spent together this week?",
        "Is there anything you need from me going into next week?",
        "What’s one thing that made you laugh this week?",
        "How did you take care of yourself this week?",
        "What's one thing we did well as a couple this week?",
        "Is there anything we need to work on together for next week?",
        "What's one goal you have for us next week?",
        "Did you feel loved and appreciated this week?",
        "What’s one thing you’re looking forward to next week?",
        "Is there anything you’ve been avoiding that we should discuss?",
        "How did our routines and habits work for us this week?",
        "What's one thing you learned this week?",
        "How did you feel about the balance of work and relaxation this week?",
        "What's one way we can make next week better?",
        "Is there anything that made you feel disconnected this week?",
        "What’s one way we can have more fun together next week?",
        "How did you handle stress this week?",
        "Is there something new you’d like to try together next week?",
        "What's one thing you want to celebrate from this week?",
        "How did we handle any conflicts or disagreements this week?",
        "Is there anything you want to apologize for from this week?",
        "What's one thing you felt proud of me for this week?",
        "How did you feel about our intimacy this week?",
        "Is there anything you want to share that’s been on your mind?",
        "What's one thing you’re hoping to achieve next week?",
        "How did we manage our time together this week?",
        "Is there anything you felt was missing from this week?",
        "What's one thing you appreciated about me this week?",
        "How did our plans and schedules work out for us this week?",
        "Is there anything you want to change for next week?",
        "What’s one way we can support each other better next week?",
        "How did you feel about our meals and eating habits this week?",
        "Is there anything you’d like to spend more time on next week?",
        "What's one thing you found challenging this week?",
        "How did you feel about our social interactions this week?",
        "Is there anything you felt we did particularly well this week?",
        "What’s one thing you want to focus on improving next week?",
        "How did we manage our household responsibilities this week?",
        "Is there anything you felt we neglected this week?",
        "What's one thing you want to thank me for this week?",
        "How did you feel about our physical activities and exercise this week?",
        "Is there anything you’re excited about for next week?"

    ],
    "Monthly": [
        "What was the biggest achievement we had this month?",
        "What's one challenge we faced this month and how did we handle it?",
        "How did we grow as a couple this month?",
        "Is there anything you wished we had done differently this month?",
        "What's one thing you were grateful for in our relationship this month?",
        "How did you feel about the quality of our time together this month?",
        "Is there anything you need more of from me going into next month?",
        "What's one fun memory we made this month?",
        "How did we support each other's goals this month?",
        "Is there anything you felt was a recurring issue this month?",
        "What's one way we can improve our relationship next month?",
        "How did we handle stress as a couple this month?",
        "Is there anything we need to address that we haven't talked about?",
        "What's one thing you appreciated about me this month?",
        "How did you feel about our intimacy this month?",
        "Is there a new activity or hobby you’d like to try together next month?",
        "What’s one thing we did well as a team this month?",
        "Is there anything you want to work on individually that could benefit our relationship?",
        "How did we handle finances together this month?",
        "What's one thing you want to celebrate from this month?",
        "Is there anything you felt disconnected from me about this month?",
        "What’s one goal you have for us next month?",
        "How did our communication work for us this month?",
        "Is there anything you want to apologize for this month?",
        "How did you feel about the balance of work and relaxation this month?",
        "Is there a special way you’d like to spend time together next month?",
        "What’s one thing you felt proud of me for this month?",
        "How did our plans and schedules align with our expectations this month?",
        "Is there anything you want to change about our routines for next month?",
        "What's one thing you’re looking forward to next month?",
        "How did we manage our household responsibilities this month?",
        "Is there anything you felt we neglected this month?",
        "What's one thing you want to thank me for this month?",
        "How did you feel about our physical activities and exercise this month?",
        "Is there anything you want to focus on improving next month?",
        "What's one thing you learned about me this month?",
        "How did we handle any conflicts or disagreements this month?",
        "Is there anything you want to share that’s been on your mind this month?",
        "What's one thing you’re hoping to achieve next month?",
        "How did we manage our social interactions this month?",
        "Is there anything you felt was missing from this month?",
        "What's one thing you appreciated about our relationship this month?",
        "How did our routines and habits work for us this month?",
        "Is there anything we need to work on together for next month?",
        "How did you feel about our meals and eating habits this month?",
        "Is there anything you’d like to spend more time on next month?",
        "What's one thing you found challenging this month?",
        "Is there anything you’re excited about for next month?",
        "What's one thing you want to focus on improving together next month?"
    ],
    Communication: [
        "What’s your preferred way of receiving support from me?",
        "How do you feel about the way we resolve conflicts?",
        "What’s one thing we can do to improve our communication?",
        "How comfortable are you expressing your feelings to me?",
        "What’s one way I can be a better listener?",
        "How do you prefer to bring up difficult topics?",
        "What’s one thing I do that makes you feel heard?",
        "How do you feel about the frequency of our deep conversations?",
        "What’s one non-verbal way we communicate well?",
        "How do you feel about the way we apologize to each other?",
        "What's one thing I can do to make you feel more comfortable sharing your thoughts?",
        "How do you feel about the way we handle misunderstandings?",
        "What’s one way we can make our conversations more meaningful?",
        "How do you prefer to receive feedback from me?",
        "What’s one thing I can do to show I’m actively listening to you?",
        "How do you feel about the way we communicate our needs?",
        "What’s one thing we can do to reduce miscommunication?",
        "How comfortable are you sharing your opinions with me?",
        "What’s one way we can ensure our conversations are balanced?",
        "How do you feel about the way we discuss our future plans?",
        "What’s one thing I can do to show empathy in our conversations?",
        "How do you feel about the way we express gratitude to each other?",
        "What’s one way we can improve our communication during arguments?",
        "How do you feel about the way we share our daily experiences?",
        "What’s one thing I can do to make our communication more open?",
        "How do you feel about the way we discuss our relationship goals?",
        "What’s one way we can improve our communication about finances?",
        "How comfortable are you discussing your emotions with me?",
        "What’s one thing I can do to make you feel more valued in our conversations?",
        "How do you feel about the way we handle sensitive topics?",
        "What’s one way we can improve our communication about intimacy?",
        "How do you feel about the way we support each other’s dreams?",
        "What’s one thing I can do to improve my communication skills?",
        "How do you feel about the way we manage our time together?",
        "What’s one way we can ensure our communication remains positive?",
        "How comfortable are you with the way we discuss our disagreements?",
        "What’s one thing we can do to make our communication more effective?",
        "How do you feel about the way we talk about our expectations?",
        "What’s one way we can improve our communication about our hobbies?",
        "How do you feel about the way we express love verbally?",
        "What’s one thing I can do to make you feel more appreciated?",
        "How do you feel about the way we communicate during stressful times?",
        "What’s one way we can improve our communication about health and wellness?",
        "How comfortable are you with the way we discuss our boundaries?",
        "What’s one thing we can do to enhance our communication during fun activities?",
        "How do you feel about the way we share our successes and failures?",
        "What’s one way we can improve our communication about our social lives?",
        "How do you feel about the way we express our frustrations?",
        "What’s one thing I can do to improve our daily communication?",
        "How do you feel about the way we handle external influences on our relationship?"
    ],



};
export default questions;