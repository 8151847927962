import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import ReactConfetti from 'react-confetti';
import questions from '../questions';

const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const QuestionScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [questionQueue, setQuestionQueue] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [isGameComplete, setIsGameComplete] = useState(false);
  const [error, setError] = useState(null);
  const [confettiProps, setConfettiProps] = useState({
    numberOfPieces: 200,
    recycle: false,
    run: false,
  });
  const [showCompletionModal, setShowCompletionModal] = useState(false);
  const [animateCompletion, setAnimateCompletion] = useState(false);

  useEffect(() => {
    if (location.state && location.state.selectedCategories) {
      const { numQuestions, selectedCategories } = location.state;
      setTotalQuestions(numQuestions);
      
      // Create a queue of questions with alternating categories
      const questionQueue = createQuestionQueue(selectedCategories, numQuestions);
      
      if (questionQueue.length === 0) {
        setError("No questions available for the selected categories.");
        return;
      }

      if (questionQueue.length < numQuestions) {
        setError(`Not enough questions available. Found ${questionQueue.length}, but ${numQuestions} were requested.`);
        return;
      }

      setQuestionQueue(questionQueue);
      setCurrentQuestion(questionQueue[0]);
    } else {
      setError("No categories selected. Please go back and select categories.");
    }
  }, [location.state]);

  const createQuestionQueue = (selectedCategories, numQuestions) => {
    // Shuffle the order of categories
    const shuffledCategories = shuffleArray(selectedCategories);
    
    // Create an object to hold shuffled questions for each category
    const categoryQuestions = {};
    shuffledCategories.forEach(category => {
      categoryQuestions[category] = shuffleArray(questions[category]);
    });

    const queue = [];
    let categoryIndex = 0;

    while (queue.length < numQuestions) {
      const category = shuffledCategories[categoryIndex];
      if (categoryQuestions[category].length > 0) {
        queue.push({
          text: categoryQuestions[category].pop(),
          category: category
        });
      }
      categoryIndex = (categoryIndex + 1) % shuffledCategories.length;
    }

    return queue;
  };

  const stopConfetti = useCallback(() => {
    setConfettiProps(prev => ({ ...prev, numberOfPieces: 0 }));
  }, []);

  const nextQuestion = () => {
    if (answeredQuestions < totalQuestions - 1) {
      const nextQuestionIndex = answeredQuestions + 1;
      setCurrentQuestion(questionQueue[nextQuestionIndex]);
      setAnsweredQuestions(nextQuestionIndex);
    } else {
      completeGame();
    }
  };

  const completeGame = () => {
    setIsGameComplete(true);
    setConfettiProps(prev => ({ ...prev, run: true }));
    setShowCompletionModal(true);
    // Delay the animation slightly to ensure the modal is rendered before animating
    setTimeout(() => {
      setAnimateCompletion(true);
    }, 50);
    setTimeout(stopConfetti, 5000); // Stop confetti after 5 seconds
  };

  const skipQuestion = () => {
    // Move the current question to the end of the queue
    setQuestionQueue(prev => [...prev.slice(1), prev[0]]);
    setCurrentQuestion(questionQueue[1]);
  };

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400 flex items-center justify-center p-4">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
          <h2 className="text-2xl font-bold mb-4 text-red-600">Error</h2>
          <p className="mb-6">{error}</p>
          <Link to="/" className="bg-blue-300 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200 font-medium">
            Go Back
          </Link>
        </div>
      </div>
    );
  }

  if (!currentQuestion && !isGameComplete) {
    return <div>Loading...</div>;
  }

  // Adjust progress calculation
  const progressStep = 90 / (totalQuestions - 1);
  const progress = 5 + (answeredQuestions * progressStep);

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <ReactConfetti
        width={window.innerWidth}
        height={window.innerHeight}
        {...confettiProps}
      />
      <div className="flex flex-col items-center w-full max-w-md">
        <div 
          className={`bg-white p-8 rounded-lg shadow-lg w-full transition-all duration-500 ease-in-out ${
            isGameComplete 
              ? showCompletionModal
                ? animateCompletion
                  ? 'opacity-100 transform scale-100 rotate-0'
                  : 'opacity-0 transform scale-50 rotate-12'
                : 'hidden'
              : ''
          }`} 
          style={{ height: '300px', display: 'flex', flexDirection: 'column' }}
        >
          {!isGameComplete && (
            <div className="mb-6 bg-gray-200 rounded-full h-2 overflow-hidden">
              <div 
                className="bg-gradient-to-r from-amber-300 via-orange-400 to-pink-400 h-full rounded-full transition-all duration-300 ease-in-out" 
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          )}
          {isGameComplete ? (
            <div className="text-center flex-grow flex flex-col items-center justify-center">
              <img 
                src="/twenty.png"
                alt="Twenty!"
                className={`mb-4 transition-all duration-500 ${
                  animateCompletion ? 'animate-pop-in' : 'opacity-0 scale-50'
                }`}
                style={{
                  fontFamily: "'Honk', cursive",
                  maxWidth: '200px', // Adjust as needed
                  height: 'auto',
                }}
              />
              <p className="text-m mb-6 mt-4 text-gray-500">
                Hopefully you took notes.
              </p>
              <Link 
                to="/" 
                className="bg-orange-300 text-white py-3 px-6 rounded-lg hover:bg-orange-500 transition duration-200 font-medium"
              >
                Start Over
              </Link>
            </div>
          ) : (
            <>
              <div className="flex-grow flex items-center justify-center">
                <h2 className="text-2xl font-bold text-center text-black">
                  {currentQuestion.text}
                </h2>
              </div>
              <div className="flex justify-between items-center mt-4">
                <button
                  className="bg-gray-200 text-gray-700 py-2 px-6 rounded-lg hover:bg-gray-300 transition duration-200 font-medium"
                  onClick={skipQuestion}
                >
                  Skip
                </button>
                <span className="bg-yellow-300 text-yellow-600 text-sm font-medium px-3 py-1 rounded-full">
                  {currentQuestion.category}
                </span>
                <button
                  className="bg-orange-300 text-white py-2 px-6 rounded-lg hover:bg-orange-500 transition duration-200 font-medium"
                  onClick={nextQuestion}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </div>
        {!isGameComplete && (
          <Link to="/" className="mt-6 text-white hover:underline font-medium">
            Another Round
          </Link>
        )}
      </div>
    </div>
  );
};

export default QuestionScreen;