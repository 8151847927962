import React, { useState, useEffect } from 'react';
import questions from '../questions';
import QuestionBackground from './QuestionBackground';

const Home = ({ onNavigate }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const numQuestions = 20;

  useEffect(() => {
    const timer = setTimeout(() => setModalVisible(true), 100);
    return () => clearTimeout(timer);
  }, []);

  const categoryOptions = Object.keys(questions);

  const toggleCategory = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const startGame = () => {
    if (selectedCategories.length === 0) {
      alert("Please select at least one category.");
      return;
    }
    onNavigate('/question', { state: { numQuestions, selectedCategories } });
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4 relative overflow-hidden">
      <QuestionBackground />
      
      <div 
        className={`bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative modal transition-all duration-500 ease-out ${
          modalVisible ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform -translate-y-4'
        }`} 
        style={{ zIndex: 10 }}
      >
       <img 
          src="/twentybest.png" 
          alt="Twenty Best"
          className="w-full mb-2 text-center"
          style={{
            maxWidth: '300px', // Adjust as needed
            margin: '0 auto',
          }}
        />
        <div className="mb-8 mt-6">
          <h2 className="text-lg font-semibold mb-4 text-center text-gray-500">Choose one category or multiple.</h2>
          <div className="grid grid-cols-2 gap-2">
            {categoryOptions.map((cat) => (
              <button
                key={cat}
                className={`py-2 px-4 rounded-lg font-medium transition duration-200 ${
                  selectedCategories.includes(cat)
                    ? 'bg-rose-400 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
                onClick={() => toggleCategory(cat)}
              >
                {cat}
              </button>
            ))}
          </div>
        </div>
        
        <button
          className="w-full bg-orange-300 text-white py-3 rounded-lg hover:bg-orange-500 transition duration-200 text-lg font-semibold"
          onClick={startGame}
        >
          Start
        </button>
      </div>
    </div>
  );
};

export default Home;