import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Home from './components/Home';
import QuestionScreen from './components/QuestionScreen';

const PageWrapper = ({ children }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = useCallback((to, options) => {
    setIsTransitioning(true);
    setTimeout(() => {
      navigate(to, options);
      setIsTransitioning(false);
    }, 300); // Match this with your CSS transition duration
  }, [navigate]);

  return (
    <div className={`page-wrapper ${isTransitioning ? 'transitioning' : ''}`}>
      {React.cloneElement(children, { onNavigate: handleNavigate })}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <div className="app-container h-full bg-gradient-to-r from-purple-500 via-pink-500 to-rose-500 overflow-hidden">
        <Routes>
          <Route path="/" element={<PageWrapper><Home /></PageWrapper>} />
          <Route path="/question" element={<PageWrapper><QuestionScreen /></PageWrapper>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;